<template>
    <div class="swiper-container sub">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="@/assets/img/glogo1.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo2.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo5.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo6.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo10.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo12.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo14.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo15.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo16.png">
        </div>
        <div class="swiper-slide">
          <img src="@/assets/img/glogo17.png">
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'UiSwipera',
  title: 'sub',
  data () {
    return {
    }
  },
  mounted () {
    this.loadSwipera()
  },
  watch: {
  },
  computed: {
  },
  methods: {
    loadSwipera () {
      // const list = this.banner
      // eslint-disable-next-line no-undef,no-new
      new Swiper('.swiper-container.sub', {
        slidesPerView: 'auto',
        spaceBetween: 10,
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      })
    }
  }
}
</script>

<style src="@/styles/swiper.min.css"></style>

<style>
.swiper-container.sub {width: calc(100% - 125px);}
.swiper-container.sub .swiper-slide {width: auto;}
.swiper-container.sub .swiper-slide img {height: 50px;}
@media (max-width: 1000px) {
   .swiper-container.sub {width: calc(100% - 82px);}
   .swiper-container.sub .swiper-slide img {height: 50px;}
}
</style>
